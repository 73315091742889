<template lang="html">
    <setting-block-layout
        @save="onSave"
        @cancel="onCancel"
    >
        <template #body>
            <div class="email_input">
                <form-input
                    v-model.trim="emailValue"
                    legend="Email address"
                    prepend-icon="EmailOutlineIcon"
                    type="text"
                    name="email"
                    :field.sync="emailValue"
                    :errors="validationMessage($v.email)"
                    :is-valid="$v.email.$dirty && !$v.email.$anyError"
                    :loading="loadEmail"
                    @input.native="$v.email.$touch()"
                    @blur.native="$v.email.$touch()"
                    @onChange="loadEmail = true"
                />
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { createNamespacedHelpers } from 'vuex';
import { debounce } from 'lodash'
import { eventBus } from '@/helpers/event-bus/'

import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/profile/ProfileEmail/Rules'
import { formMessages } from '@/validation/profile/ProfileEmail/Messages'

import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';

import {
    ACTION_CLIENT_UPDATE_EMAIL
} from '@/store/modules/client/action-types';
import {
    mapClientFields
} from '@/store/modules/client';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingBlockLayout
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    metaInfo: {
        title: 'Change email'
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            loadEmail: false,
            emailValue: '',
            email: ''
        }
    },
    computed: {
        ...mapClientFields({
            storeEmail: 'client_data.email'
        })
    },
    watch: {
        emailValue: debounce(function (newEmail) {
            this.email = newEmail.trim()
            this.loadEmail = false
        }, 300)
    },
    created() {
        this.emailValue = this.storeEmail;
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapClientActions({
            updateEmail: ACTION_CLIENT_UPDATE_EMAIL
        }),
        async onSave() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
                return
            }
            try {
                await this.updateEmail({ email: this.email })
                eventBus.$emit('showSnackBar', 'Your e-mail has been changed', 'success');
                this.$router.push({ name: 'profile' })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
            }
        },
        onCancel() {
            this.$router.push({ name: 'profile' })
        }
    }
}
</script>

<style lang="scss" >
    .email_input {
        display: flex;
        justify-content: center;
        width: 50%;
        margin: 0 auto;
        @media all and (max-width: 768px){
            width: 100%;
        }
    }
</style>
