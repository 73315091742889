import {
    email,
    required
} from 'vuelidate/lib/validators';

export const validationRules = {
    email: {
        required,
        email: (val) => email(val.toLowerCase()),
        async emailExists(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.email.required || !this.$v.email.email) { return true; }

            return new Promise((resolve, reject) => {
                this.checkEmail({ email: value })
                    .then(() => {
                        resolve(true)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }
};
